<template>
    <user-dashboard-layout>
        <v-container class="fill-height mt-15" v-if="loading">
            <v-col class="text-center">
                <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate/>
            </v-col>
        </v-container>
        <v-container v-else>
            <v-row dense>
                <v-col md="4" v-for="(col, colIdx) in $_.chunk(tags, Math.ceil(tags.length / 3))" :key="colIdx" class="mb-0 pb-0">
                    <v-list-item v-for="(tag, tagIdx) in col" :key="tagIdx" dense class="pointer">
                        <v-list-item-content>
                            <v-list-item-title
                                :class="{active: $route.query.tag === tag.name}"
                                @click="$router.push({query: {tag: tag.name}})"
                                v-text="tag.name"/>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
            <v-row>
                <timeframe-select/>
            </v-row>
            <v-row dense>
                <v-col sm="12" md="6" xl="3"  v-for="video in videos" :key="video.id" >
                    <video-card :video="video"/>
                </v-col>
            </v-row>
        </v-container>
    </user-dashboard-layout>
</template>

<script>
    import UserDashboardLayout from "../../../layouts/UserDashboardLayout";
    import Video from "../../../models/Video";
    import VideoCard from "../../../components/cards/VideoCard";
    import Tag from "@/models/Tag";
    import TimeframeSelect from "@/views/partials/TimeframeSelect";

    export default {
        name: "Index",
        components: {TimeframeSelect, VideoCard, UserDashboardLayout},
        data: function () {
            return {
                videos: [],
                loading: false,
                tags: [],
                activeTag: []
            }
        },
        async mounted() {
            this.loading = true
            this.tags = await Tag.where('has_videos', true).get();
            await this.getVideos()
            this.loading = false
        },
        methods: {
            async getVideos() {
                let query = Video.where('industry_id', this.$auth.user().industry_id);

                if (this.$route.query.lastDays) {
                    query.where('lastDays', this.$route.query.lastDays);
                }

                this.videos = this.$route.query.tag
                    ?  await query.where('has_tag', this.$route.query.tag).include(['tags']).get()
                    :  await query.include(['tags']).get()
            },
            removeChip() {
                this.$router.replace({'query': null});
            }
        },
        watch: {
            "$route.query": async function (val, oldVal) {
                if (val !== oldVal) {
                    await this.getVideos();
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .pointer {
        cursor: pointer;
        &:hover{
            background: var(--v-secondary-lighten5);
        }
    }
</style>

<style lang="scss">
    .v-list-item__title.active {
        font-weight: bold;
        color: #0d47a1;
    }
</style>
